import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Container, Grid, GridItem, Heading, Text } from '@charlietango/ui';
import { rem } from 'polished';

import Anchor from '../../components/Anchor/Anchor';
import Logo from '../../components/Logo/Logo';
import AdressIcon from '../../icons/AdressIcon';
import ArrowRightIcon from '../../icons/ArrowRightIcon';
import ContactIcon from '../../icons/ContactIcon';
import CrownFooterDesktopIcon from '../../icons/CrownFooterDesktopIcon';
import MobileIcon from '../../icons/MobileIcon';
import { Colors } from '../../styles/colors';
import { FontWeight, TextVariant } from '../../styles/typography';
import LinkViewModel from '../../view-models/LinkViewModel';

export type FooterProps = {
  title?: string;
  description?: string;
  subTitle?: string;
  subDescription?: string;
  links?: {
    title?: string;
    items?: LinkViewModel[];
  };
  contact?: {
    title?: string;
    address?: string;
    phone?: LinkViewModel;
    email?: LinkViewModel;
  };
};

const ulStyle = {
  color: Colors.Grey,
  listStyle: 'none',
  paddingLeft: 0,
  marginTop: 22,
  marginBottom: 0,
};

const liStyle = {
  marginTop: 19,
  fontSize: 1,
  maxWidth: '100%',
};

const linkStyle = {
  color: Colors.Grey,
  textDecoration: 'none',
  '&:hover, &:focus': { color: Colors.White },
};

const IconContainerStyle = {
  textAlign: ['center', null, 'right'],
  alignSelf: 'flex-start',
};

const IconStyle = {
  maxWidth: [31, 40, 50],
};

const ContactIconStyle = {
  flex: '0 0 18px',
};

//H3 and paragraph differ from the default typography
const H3Style = {
  fontSize: [2, 3],
  lineHeight: [1.88, 1.5],
  mb: 3,
};

const ParagraphStyle = {
  fontSize: 1,
};

type ListItemProps = {
  icon: React.ReactNode;
  children?: React.ReactNode;
};

const ListItem = ({ icon, children }: ListItemProps) => {
  return (
    <span
      sx={{
        display: 'flex',
        alignItems: 'center',
        maxWidth: '100%',
        overflowWrap: 'break-word',
      }}
    >
      {icon}
      <span
        sx={{
          marginLeft: rem(10),
          overflowWrap: 'break-word',
          maxWidth: '100%',
        }}
      >
        {children}
      </span>
    </span>
  );
};

export function Footer({
  title,
  description,
  subTitle,
  subDescription,
  links,
  contact,
}: FooterProps) {
  return (
    <footer sx={{ color: Colors.White, backgroundColor: Colors.Black }}>
      <Container
        sx={{
          pt: [40, 50, 70],
          pb: [null, 10, 30],
        }}
      >
        <Grid gridColumns={[8, 8, 12]}>
          <GridItem size={[1 / 8, null, 1 / 12]} sx={IconContainerStyle}>
            <CrownFooterDesktopIcon sx={IconStyle} />
          </GridItem>
          <GridItem size={[7 / 8, null, 11 / 12]} sx={{ marginTop: rem(10) }}>
            <Heading variant={TextVariant.H3} sx={H3Style}>
              {title}
            </Heading>
            {description && (
              <Text
                sx={{
                  maxWidth: 981,
                  lineHeight: 1.17,
                  fontSize: [4, 5, 7],
                  fontWeight: FontWeight.Medium,
                  overflowWrap: 'break-word',
                  wordBreak: 'break-word',
                  mb: [40, null, 64],
                }}
              >
                {description}
              </Text>
            )}
          </GridItem>
          <GridItem size={[1 / 8, null, 1 / 12]} sx={IconContainerStyle}>
            <Logo sx={{ ...IconStyle, maxHeight: 50 }} />
          </GridItem>
          <GridItem
            size={[7 / 8, null, 11 / 12]}
            sx={{ mt: [0, rem(10)], mb: [7, 0] }}
          >
            <Grid gridGap={[0, '1rem']} gridColumns={[7, 7, 11]}>
              <GridItem
                sx={{
                  mb: 8,
                }}
                size={[6 / 8, 1 / 2, 3 / 12]}
              >
                <Heading variant={TextVariant.H3} sx={H3Style}>
                  {subTitle}
                </Heading>
                <Text variant={TextVariant.Paragraph} sx={ParagraphStyle}>
                  {subDescription}
                </Text>
              </GridItem>
              <GridItem size={[null, 6 / 8, 1 / 12]} />
              {links && (
                <GridItem size={[7 / 8, 3 / 8, 3 / 12]} sx={{ mb: 8 }}>
                  {links.title && (
                    <Heading variant={TextVariant.H3} sx={H3Style}>
                      {links.title}
                    </Heading>
                  )}
                  {links?.items && (
                    <ul sx={ulStyle}>
                      {links.items.map((item, index) => (
                        <li key={index} sx={liStyle}>
                          <Anchor prefetch {...item} sx={linkStyle}>
                            <ListItem
                              icon={
                                <ArrowRightIcon
                                  sx={{
                                    flex: '0 0 8px',
                                    color: Colors.Primary,
                                  }}
                                />
                              }
                            >
                              {item.label}
                            </ListItem>
                          </Anchor>
                        </li>
                      ))}
                    </ul>
                  )}
                </GridItem>
              )}
              {contact && (
                <GridItem size={[7 / 8, 3 / 8, 3 / 12]} sx={{ mb: 8 }}>
                  {contact?.title && (
                    <Heading variant={TextVariant.H3} sx={H3Style}>
                      {contact.title}
                    </Heading>
                  )}
                  <ul sx={ulStyle}>
                    {contact?.email?.href && (
                      <li sx={liStyle}>
                        <Anchor
                          href={contact.email.href}
                          sx={{
                            ...linkStyle,
                            textDecoration: 'underline',
                          }}
                        >
                          <ListItem
                            icon={<ContactIcon css={ContactIconStyle} />}
                          >
                            {contact.email.label}
                          </ListItem>
                        </Anchor>
                      </li>
                    )}
                    {contact.address && (
                      <li sx={liStyle}>
                        <ListItem icon={<AdressIcon css={ContactIconStyle} />}>
                          {contact.address}
                        </ListItem>
                      </li>
                    )}
                    {contact?.phone?.href && (
                      <li sx={liStyle}>
                        <Anchor
                          href={contact.phone.href}
                          sx={{
                            ...linkStyle,
                            textDecoration: 'underline',
                          }}
                        >
                          <ListItem
                            icon={<MobileIcon css={ContactIconStyle} />}
                          >
                            {contact.phone.label}
                          </ListItem>
                        </Anchor>
                      </li>
                    )}
                  </ul>
                </GridItem>
              )}
            </Grid>
          </GridItem>
        </Grid>
      </Container>
    </footer>
  );
}

export default !process.env.HOT ? Footer : hot(Footer);
